import { Button, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay} from "@chakra-ui/react"
import { FC, memo } from "react"

type Props = {
  modalTitle: string;
  modalBody: string;
  isOpen: boolean;
  onOpen: () => void;
  onClose: () => void;
};

export const BaseModal: FC<Props> = memo((props) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { modalTitle, modalBody, isOpen, onOpen, onClose } = props;
  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{modalTitle}</ModalHeader>
          <ModalCloseButton />
          <ModalBody style={{ whiteSpace: 'pre-line' }}>
            {modalBody}
          </ModalBody>

          <ModalFooter>
            <Button colorScheme='teal' mr={3} onClick={onClose}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
})