import { PlayerType } from "../components/types/PlayerType";

export const PlayerData: PlayerType[] = [
  {
    playerId: "1",
    teamId: "xxx",
    userId: "taro.yamada",
    uniformNumber: 1,
    playerName: "山田 太郎",
    age: 28,
    position: "P",
    pitching: "右",
    batting: "右",
    joinYear: "2023",
    role: "選手",
  },
  {
    playerId: "2",
    teamId: "xxx",
    userId: "taro.yamada",
    uniformNumber: 10,
    playerName: "高橋 次郎",
    age: 30,
    position: "2B",
    pitching: "右",
    batting: "左",
    joinYear: "2023",
    role: "選手",
  },
  {
    playerId: "3",
    teamId: "xxx",
    userId: "taro.yamada",
    uniformNumber: 18,
    playerName: "一二三 晋平太",
    age: 28,
    position: "3B",
    pitching: "右",
    batting: "右",
    joinYear: "2023",
    role: "選手",
  },
];
