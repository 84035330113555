import { fetchAuthSession } from 'aws-amplify/auth';
import { useCallback, useEffect, useState } from 'react';

interface UseApiRequestResult<T> {
  response: T | null;
  error: string | null;
  loading: boolean;
  sendRequest: (newBody?: Record<string, unknown>) => Promise<T>;
}

const useApiRequest = <T>(
  url: string,
  method: 'GET' | 'POST' | 'PUT' | 'DELETE',
  body: Record<string, unknown> | null = null,
  trigger: boolean = false
): UseApiRequestResult<T> => {
  const [response, setResponse] = useState<T | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const sendRequest = useCallback(async (newBody: Record<string, unknown> | null = body) => {
    setLoading(true);
    setError(null);

    try {
      const idToken = (await fetchAuthSession()).tokens?.idToken;
      const headers: HeadersInit = {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${idToken}`,
      };

      const options: RequestInit = {
        method,
        headers,
        body: newBody && method !== 'GET' ? JSON.stringify(newBody) : null,
      };

      const response = await fetch(url, options);

      const result: T = await response.json();
      setResponse(result);
      return result;
    } catch (err: unknown) {
      setError((err as Error).message);
      return {"message": (err as Error).message} as T;
    } finally {
      setLoading(false);
    }
  }, [url, method, body]);

  useEffect(() => {
    if (trigger) {
      sendRequest();
    }
  }, [trigger, sendRequest]);

  return { response: response, error, loading, sendRequest };
};

export default useApiRequest;
