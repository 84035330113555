import { Flex, useDisclosure } from "@chakra-ui/react";
import { memo, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import useApiRequest from "../../../hooks/useApiRequest";
import { FormBox } from "../../atoms/FormBox";
import { BaseModal } from "../../atoms/modal/BaseModal";
import { TopTitle } from "../../atoms/title/TopTitle";
import { PlayerForm } from "../../organisms/form/PlayerForm";
import { InsertPlayersRequest } from "../../types/api/insertPlayersRequest";
import { InsertPlayersResponse } from "../../types/api/insertPlayersResponse";

export const PlayerAdd = memo(() => {
  const {onOpen, isOpen, onClose} = useDisclosure();
  const methods = useForm<InsertPlayersRequest>();
  const [modalBody, setModalBody] = useState("")
  const { sendRequest } = useApiRequest<InsertPlayersResponse>(
    String(process.env.REACT_APP_API_URL) + "players",
    "POST"
  );

  const onSubmit = async (data: InsertPlayersRequest) => {
    data.team_id = 'WINXID' // @TODO team_idをどうするか？バックエンドで実装するか？フロントエンドならどこからとるか？
    console.log(data);
    // POSTリクエストを送信
    const response = await sendRequest(data);
    console.log(response)
    setModalBody("ユーザーID："+ response.user_id + "\nパスワード：" + response.password + "\nメッセージ：" + response.message)
    onOpen()
  };

  return (
    <>
      <TopTitle>選手登録フォーム</TopTitle>
      <Flex align="top" justify="center">
        <FormBox>
          <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(onSubmit)}>
              <PlayerForm />
            </form>
          </FormProvider>
        </FormBox>
        <BaseModal modalTitle={"登録選手情報（メモ必須）"} modalBody={modalBody} onOpen={onOpen} isOpen={isOpen} onClose={onClose}/>
      </Flex>
    </>
  );
});
