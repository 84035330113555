import { Button, Flex, Grid, GridItem } from "@chakra-ui/react";
import { FC } from "react";
import { useFormContext } from "react-hook-form";
import { RLData } from "../../../data/RLData";
import { positionData } from "../../../data/positionData";
import { FormNumberInput } from "../../molecules/formInput/FormNumberInput";
import { FormSelect } from "../../molecules/formInput/FormSelect";
import { FormTextInput } from "../../molecules/formInput/FormTextInput";
import { PlayerType } from "../../types/PlayerType";

type Props = {
  player?: PlayerType;
};

export const PlayerForm: FC<Props> = (props) => {
  const { player } = props;

  const {
    register,
    formState: { errors, isSubmitting },
  } = useFormContext();

  // teamIdをformにどうにか入れる
  // teamIdがパスにないので、cognitoに入れるか、sessionStorageに入れるか、パスに入れるかどうにか
  return (
    <>
      <Grid templateColumns="repeat(2, 1fr)" gap={4} p={3}>
        <GridItem>
          <FormTextInput
            id="userId"
            label="ユーザーID"
            placeholder=""
            isInvalid={errors?.role}
            defaultValue={player?.role}
            register={register("user_id", {
              required: "入力必須です",
            })}
          />
        </GridItem>
        <GridItem>
          <FormTextInput
            id="playerName"
            label="選手名"
            placeholder=""
            isInvalid={errors?.role}
            defaultValue={player?.role}
            register={register("player_name", {
              required: "入力必須です",
            })}
          />
        </GridItem>
        <GridItem>
          <FormNumberInput
            id="uniformNumber"
            label="背番号"
            placeholder=""
            isInvalid={errors?.playerNo}
            defaultValue={player?.uniformNumber}
            register={register("uniform_number", {
              required: "入力必須です",
              valueAsNumber: true,
            })}
          />
        </GridItem>
        <GridItem>
          <FormNumberInput
            id="age"
            label="年齢"
            placeholder=""
            isInvalid={errors?.age}
            defaultValue={player?.age}
            register={register("age", {
              required: "入力必須です",
              valueAsNumber: true,
            })}
          />
        </GridItem>
        <GridItem>
          <FormSelect
            id="position"
            label="守備位置"
            placeholder="-"
            isInvalid={errors?.position}
            defaultValue={player?.position}
            register={register("position", {
              required: "入力必須です",
            })}
            optionData={positionData}
          />
        </GridItem>
        <GridItem>
          <FormSelect
            id="pitching"
            label="投球"
            placeholder="-"
            isInvalid={errors?.throw}
            defaultValue={player?.pitching}
            register={register("pitching", {
              required: "入力必須です",
            })}
            optionData={RLData}
          />
        </GridItem>
        <GridItem>
          <FormSelect
            id="batting"
            label="打席"
            placeholder="-"
            isInvalid={errors?.hit}
            defaultValue={player?.batting}
            register={register("batting", {
              required: "入力必須です",
            })}
            optionData={RLData}
          />
        </GridItem>
        <GridItem>
          <FormSelect
            id="joinYear"
            label="入部年"
            placeholder=""
            isInvalid={errors?.joinYear}
            register={register("join_year", {
              required: "入力必須です",
            })}
            defaultValue={
              player?.joinYear === undefined
                ? new Date().getFullYear().toString()
                : player?.joinYear
            }
            optionData={Array.from(
              { length: 101 },
              (_, i) => (new Date().getFullYear() - 50 + i).toString()
            )}
          />
        </GridItem>
        <GridItem>
          <FormTextInput
            id="role"
            label="役割"
            placeholder=""
            isInvalid={errors?.role}
            defaultValue={player?.role}
            register={register("role", {
              required: "入力必須です",
            })}
          />
        </GridItem>
        <GridItem colSpan={2}>
          <Flex justify="right">
            <Button
              mt={4}
              bg="teal.400"
              color="white"
              _hover={{ opacity: 0.8 }}
              isLoading={isSubmitting}
              type="submit"
            >
              保存
            </Button>
          </Flex>
        </GridItem>
      </Grid>
    </>
  );
};
